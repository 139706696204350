import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "Accompagnement",
    path: "accueil / nos métiers / accompagnement / Assistant de gestion"
  },
  title: "Assistant de gestion",
  missions:[
    {
  		text: "Missions principales : " 
	},
	{
	  text: "Assurer une fonction de support à l’administration des ventes et de suivi des achats, au chef de projet ou tout autre service" 
	},
	],
	key_skills: [
		{
		  text: "Expression orale et écrite" 
		},
		{
		  text: "Etre consciencieux" 
		},
		{
		  text: "Analyser et comprendre" 
		},
		{
		  text: "Collaborer" 
		},
	],
	prerequisites:[
		{
		  text: "Bac + une formation qualifiante en gestion OU une expérience d’au moins deux ans dans le métier" 
		},
		{
		  text: "Expression orale et écrite adaptée à un contexte professionnel" 
		},
	],
	activities:[
		{
		  text: "Administration des Ventes" 
		},
		{
		  text: "Gérer l’administration et relancer les clients" 
		},
		{
		  text: "Suivre les contrats et commandes clients" 
		},
		{
		  text: "Adresser les contrats aux clients" 
		},
		{
		  text: "Relancer les clients pour le suivi des commandes en relation avec l’équipe commerciale" 
		},
		{
		  text: "Mettre à jour les bases contrats, classement et échéances" 
		},
		{
		  text: "Etablir la facturation en relation avec les équipes commerciales et Opérationnelles" 
		},
		{
		  text: "Participer à l’élaboration des FAE / PCA (avec Commerce & CDG)" 
		},
		{
		  text: "Suivi des comptes" 
		},
		{
		  text: "Vérifier l’avancement de la planification" 
		},
		{
		  text: "Contrôler la validation des feuilles de temps" 
		},
		{
		  text: "Supporter les équipes opérationnelles (aide sur projet, création collaborateur, etc.)" 
		},
		{
		  text: "Gestion de la sous-traitance" 
		},
		{
		  text: "Imputer les coûts de sous-traitance" 
		},
		{
		  text: "Valider les factures fournisseurs" 
		},
		{
		  text: "Mettre à jour le tableau d’analyse de la sous-traitance (charges / ventes + rapprochement comptable)" 
		}
	]
}


const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;